.MainAppContainer{
  height: 100%;
  /* overflow-y: scroll; */
  /* border: 2px solid red; */
  box-sizing: border-box;
  width: 100vw;
  /* overflow: hidden; */
  position: relative;
  /* margin: 0 auto; */
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--box-shadow);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #7B738D;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}