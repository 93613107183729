*{
  margin: 0;
  padding: 0;
}
/* @font-face {
  font-family: Roboto;
  src: url('https://res.cloudinary.com/aniket98571/raw/upload/v1692336846/Roboto_fd8dx2.ttf');
  font-display: swap;
}
@font-face {
  font-family: Arial;
  src: url('https://res.cloudinary.com/aniket98571/raw/upload/v1692336854/Arial_o5ddt0.TTF');
  font-display: swap;
} */
@font-face {
  font-family: Rubik;
  src: url('https://res.cloudinary.com/aniket98571/raw/upload/v1708330708/DMSans-Regular_fof0ee.ttf');
  font-display: swap;
}
@font-face {
  font-family: Geist-Regular;
  src: url('https://res.cloudinary.com/aniket98571/raw/upload/v1715232194/Geist-Regular_vvwe3i.ttf');
  font-display: swap;
}
@font-face {
  font-family: Geist-Semibold;
  src: url('https://res.cloudinary.com/aniket98571/raw/upload/v1715256918/Geist-SemiBold_h3w290.ttf');
  font-display: swap;
}
body {
  font-family:Rubik, 'Arial', Helvetica, sans-serif;
  overflow-x: hidden;
  background: var(--home-page-background);
}
input, textarea{
  font-size: var(--S-small);
}
body p{
  font-family: Geist-Regular;
}

#root{
  height: 100%;
  background: var(--home-page-background);
}
[class^='react-joyride__spotlight']{
  box-shadow: 0 0 10px rgb(183 167 167 / 50%);
}
[class^="BugInitiator_ReportIconContainer"]  {
    opacity: 0 !important;
    z-index: -999!important;
   display: none !important;
}
[class^="ReportingWindow_Hide"]  {
  z-index: -9999;
}
[class^='ReportingWindow_UpperContainer'] > [class^='ReportingWindow'] img{
  filter: brightness(0) invert(1) !important;
}
[class^='ReportingWindow_UpperContainer'] > [class^='ReportingWindow_CrossContainer'] img{
  margin-top: 0px !important;
}


[class^="ReportingWindow_StopRecordingContainer"]{
   right: 279px !important;
    top: 12px;
    bottom: none;
}
[class^='ReportingWindow_ReportingWindowContainer']{
  bottom: 4% !important;
    top: unset !important;
}
[class^='ReportingWindow_UpperContainer']{
  padding: 15px 15px 15px 15px !important;
}
[class^='ReportingWindow_UpperContainer'] p{
  color: var(--white) !important;
}

[class^="ReportingWindow_MainContainer_"] >  [class^="ReportingWindow_TextContainer"] > p:nth-child(2){
  display: none;
}


/* React quill css */
.quill{
  height: 90%;
  width: 100%;
}
.ql-picker-label:hover, .ql-picker-item:hover{
  color: var(--purple) !important;
}
.ql-formats svg:hover *{
  stroke: var(--purple) !important;
}
.ql-picker-label:hover svg *{
  stroke: var(--purple) !important;
}
.ql-toolbar.ql-snow{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px !important;
  background-color: var( --home-page-background);
}
.ql-editor{
  border: 1px solid var( --chat-lines)!important;
  background-color: var( --home-page-background);
}
.ql-editor p{
  color: var( --home-page-black-text);
}
/* Hide the scrollbar thumb (the draggable part) for the ".ace" class */
.ql-editor::-webkit-scrollbar-thumb {
  display: none;
}
/* Hide the scrollbar track (the area behind the thumb) for the ".ace" class */
.ql-editor::-webkit-scrollbar-track {
  display: none;
}
#logo {
  display: none !important;
}

/* For Firefox */
/* Hide the scrollbar for the ".ace" class */
.ql-editor {
  scrollbar-width: none;
}
.track-0{
  border: 2px solid var(--dark-purple);
  position: relative;
  z-index: 2;
}
.track-1{
  border: 2px solid var(--track-one);
  position: relative;
  z-index: 2;
}
.wrapper{
  width: 100%;
  
}
.container{
  height: 12px;
  border-radius: 10px;
}
.barCompleted{
  border-radius: 10px;
}
.BRT{
  opacity: 0;
}
/* React quill css */
:root{
  --purple:#9A79EE;
  --main-purple:#B79BFF;
  --hover-purple:#A381FF;
  --grey:#7f7f7f;
  --white-shade:#ffffff80;
  --collapse-arrow-background:#DFDFDF;
  --box-shadow:#0000001a;
  --light-image-filter:invert(1);
  --grey-3:#00000080;
  --grey-4:#00000099;
  --grey-5:#00000033;
  --grey-6:#0000004d;
  --grey-7:#00000066;
  --grey-8:#000000cc;
  --tag-button-shadow:#00000066;
  --dark-purple:#8250FF;
  --icons-filter:invert(52%) sepia(0%) saturate(694%) hue-rotate(237deg) brightness(97%) contrast(90%);
  --purple-filter:invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(109%) contrast(107%);
  --white:#ffffff;
  --black:#000000;
  --chat-background:#f8f8f8;
  --scroll: #7B738D;
  --chat-active:#DEDEDE;
  --black-color:#1D1D1D;
   --red:#FF0000;
  --plus-button-background:#C3AFF5;
  --arrow-hover:#d2d2d24d;
  --select-hover:#FBFBFB;
  --M-large:60px;
  --L-large:50px;
  --LM-large:40px;
  --X-large:64px;
  --M-medium:36px;
  --X-snaller:30px;
  --M-X-smaller:28px;
  --l-X-smaller:25px;
  --l-smaller:22px;
  --smaller:20px;
  --smallest:18px;
  --S-smallest:17px;
  --S-small:16px;
  --SS-small:15px;
  --small:14px;
  --l-l-small:12px;
  --border-radius:10px;
  --border-radius-profile:30px;
  --home-page-background:#ffffff;
  --home-page-sub-text:#7f7f7f;
  --border-radius-color:#1D1D1D;
 --home-page-black-text:#1D1D1D;
 --home-box-strokes:#ffffff;
 --home-page-list:#7f7f7f;
 --black-image-filter:invert(0);
  --home-elipse-gradient: unset;
  --home-horizontal-line: #101120;
  --left-app-background:#f8f8f8;
  --left-chat-background:#ffffff;
  --chat-box-border:#1D1D1D;
  --chat-buttons:#8250FF;
  --button-border:#B79BFF;
  --chatbot-text-color:#E3E3E3;
  --chat-left-list:#00000080;
  --chat-left-list-bg:#37298405;
  --chat-lines:#77757533;
  --custom-select-hover:#FBFBFB;
  --custom-select-list-hover:#0000001a;
  --single-chat-hover:#1D1D1D;
  --single-chat-active-background:#ffffff;
  --user-name-right-chat:#00000099;
  --closing-container-arrow:#DFDFDF;
  --closing-container-arrow-border:#DFDFDF;
  --closing-arrow-image-filter:unset;
  --token-count-container:#ffffff;
 --token-count-text:#1D1D1D;
 --input-message-border:#ffffff;
 --message-input-placeholder:#00000080;
 --dynamic-token-count-background:#f8f8f8;
 --custom-select-three-border:#00000033;
 --tags-text:#1D1D1D;
 --dataset-filter-background:#f8f8f8;
 --track-zero:#00000066;
 --track-one:#00000033;
 --star-active-filter:unset;
 --collapse-arrow-hover:#D2D2D2;
 --tags-boxes-border:#00000080;
 --toggle-tracker-color:#0000001a;
 --indexer-box-background:#1D1D1D;
 --indexer-box-color:#ffffff;
 --hint-box-opacity:0.3;
 --side-box-opacity:0.8;
 --hover-custom-select-three-li:#0000001a;
 --custom-tag-arrow:invert(0) brightness(0);
 --dataset-filter-add-button:#ffffff;
 --profile-logout:transparent;
 --popup-background:unset;
 --footer-text-hover:#00000066;
 --footer-text-hover-other:#7f7f7f;
 --custom-select-background:#ffffff;
 --stop-box-stroke:#1D1D1D;
 --single-message-select:#0000001a;
 --tags-hover:#0000001a;
 --api-text-color:#B79BFF;
 --chat-boxes-border:#1D1D1D;
 --chat-header-border:#ffffff;
 --services-icon-filter: invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(109%) contrast(107%);
 --chat-icon-filter:invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(150%) contrast(107%);
 --light-mobile-home:#B79BFF;
 --home-mobile-header-logo:invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(160%) contrast(107%);
 --collaborators-logo-filter: unset;
 --max-width-container: 1170px;
 --home-header-gradient:linear-gradient(to top, rgba(183, 155, 255, 0.3), rgba(183, 155, 255, 0));
 --chatbot-active-state-color:#deffd2;
 --chatbot-active-indicator:#41873F;
 --robot-message:#F0EBFF;
 --code-background:hsl(0deg 22.68% 93.67% / 51.3%);
  --secondary-purple:#b092ff26;
 --dark-purple: #8250FF;
 --home-header-profile-image: brightness(0) saturate(100%) invert(38%) sepia(69%) saturate(5125%) hue-rotate(243deg) brightness(101%) contrast(102%);
 --faq-text: #7f7f7f;
 --dark-purple-image-filter: brightness(0) saturate(100%) invert(30%) sepia(47%) saturate(4054%) hue-rotate(244deg) brightness(104%) contrast(101%);
 }


 [data-theme="dark"] {
  --home-page-background:#101120;
  --home-page-sub-text:#ffffff;
  --invert-page-text:#040405;
  --home-page-black-text:#ffffff;
  --bg-gradient:linear-gradient(to bottom, rgba(115, 94, 165, 0.2), rgba(115, 94, 165, 0.2));
  --border-radius-color:#ffffff;
  --home-box-strokes:#8F7AC8;
  --stop-box-stroke:#8F7AC8;
  --button-bg:#271E4D;
  --add-tag-bg:#8250ff33;
  --chat-left-list-bg:#17162B;
  --light-button-bg:#8250ff33;
  --light-button-text:#ffffff;
  --light-button-border:#ffffff;
  --home-page-list:#E7E4E4;
  --code-background:#222;
  --new-purple-text:#ffffff;
  --black-image-filter:brightness(0) invert(1) ;
  --light-image-filter:invert(0);
  --home-horizontal-line: #E7E4E4;
  --hover-custom-select-three:#09070f26;
  --hover-custom-select-three-li:#A381FF;
  --home-elipse-gradient: linear-gradient(180deg, rgba(183, 155, 255, 0.6) 0%, rgba(207, 191, 250, 0.6) 100%);
  --left-app-background:#101120;
  --left-chat-background:#282541;
  --chat-box-border:#E7E4E4;
  --chat-buttons:#b79bff26;
  --button-border:#B79BFF;
  --chat-left-list:#8C8D94;
  --flatedges-container-border:#8250FF;
  --chat-hover-purple: #d4c7f6;
  --api-text-color:#ffffff;
  --chat-lines:#8C8D94;
 --custom-select-hover :#433A63;
  --custom-select-list-hover:#282541;
  --single-chat-hover:#E7E4E4;
  --single-chat-active-background:#1A1B29;
  --user-name-right-chat:#8C8D94;
  --closing-container-arrow:#b79bff26;
  --closing-container-arrow-border:#8F7AC8;
  --closing-arrow-image-filter: brightness(0) invert(1);
  --token-count-container:#b79bff26;
  --token-count-text:#E7E4E4;
  --input-message-border:#8C8D94;
  --message-input-placeholder:#8C8D94;
  --icons-filter-one: brightness(0) invert(1);
  --dynamic-token-count-background:#b79bff26;
  --tags-text:#8C8D94;
  --chat-right-border:#2c2e31;
  --dataset-filter-background:#101120;
  --track-zero:#B79BFF;
  --track-one:#fff;
  --star-active-filter: invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(109%) contrast(107%);
  --collapse-arrow-hover:#b79bff26;
  --tags-boxes-border:#B79BFF;
  --toggle-tracker-color:#ffffff;
  --indexer-box-background:#282541;
  --indexer-box-color:#ffffff;
  --hint-box-opacity:1;
  --side-box-opacity:1;
  --custom-tag-arrow:brightness(0) invert(1);
  --custom-select-three-border:#8F7AC8;
  --dataset-filter-add-button:#b79bff26;
  --header-logo-filter:brightness(0%) invert(1);
  --profile-logout:#b79bff26;
  --popup-background:rgba(255, 255, 255, 0.59);
  --hint-box-para-text:#ffffff;
  --hint-box-link-text:#B79BFF;
  --footer-text-hover:#ffffff;
  --footer-text-hover-other:#ffffff;
  --custom-select-background:#1A1B29;
  --single-message-select:#b79bff26;
  --tags-hover: #282540;
  --chat-boxes-border:#B79BFF;
  --chat-header-border:#B79BFF;
  --services-icon-filter: brightness(0) invert(1);
  --select-option-ul-icon-filter:brightness(1) invert(1);
  --chat-icon-filter:brightness(0) invert(1);
  --light-mobile-home:#101120;
  --model-input-border: 1.8px solid var(--chat-lines);
  --home-mobile-header-logo:brightness(0) invert(1);
  --api-chat-image:brightness(0) invert(1);
  --collaborators-logo-filter: unset;
  --home-header-gradient:#101120;
  --text-logo-filter:unset;
  --home-header-button-border:#B79BFF;
  --home-header-button-background:#101120;
  --home-header-button-hover:#A381FF;
  --home-header-button-hover-text:#ffffff;
  --home-header-theme-filter:brightness(0) invert(1);

  --background-colour: var(--dark-purple);
  --text-color: var(--white);
  --chat-image-filter: brightness(0) saturate(100%) invert(30%) sepia(47%) saturate(4054%) hue-rotate(244deg) brightness(104%) contrast(101%);
  --chat-user-image-filter: brightness(0) saturate(100%) invert(61%) sepia(6%) saturate(326%) hue-rotate(195deg) brightness(92%) contrast(83%);
}
[data-theme="light"] {
 --home-page-background:#ffffff;
 --light-image-filter:invert(1);
 --bg-gradient:#ffffff;
 --chat-left-list-bg:#37298405;
 --button-bg:#37298405;
 --new-purple-text:#8250FF;
 --add-tag-bg:#8250FF;
 --light-button-bg:#efedfc;
  --light-button-border:#372984;
  --light-button-text:#372984;
 --invert-page-text:#ffffff;
 --border-radius-color:#1D1D1D;
 --home-page-sub-text:#3A3841;
--home-page-black-text:#040405;
--api-text-color:#B79BFF;
--home-box-strokes:#ffffff;
--home-page-list:#3A3841;
--icons-filter-one:invert(52%) sepia(0%) saturate(694%) hue-rotate(237deg) brightness(97%) contrast(90%);
--custom-select-three-border:#00000033;
--black-image-filter:invert(0);
 --home-elipse-gradient: unset;
 --home-horizontal-line: #101120;
 --hover-custom-select-three-li:#0000001a;
 --left-app-background:#f8f8f8;
 --left-chat-background:#ffffff;
 --flatedges-container-border:#ffffff
 --chat-box-border:#1D1D1D;
 --chat-right-border:#e2e3e6;
 --hover-custom-select-three:#A381FF;
 --chat-hover-purple: #FBFBFD;

 --chat-buttons:#8250FF;
 --button-border:#B79BFF;
 --chat-left-list:#00000080;
 --header-logo-filter:brightness(100%);
 --side-nav-link-opacity:1;
 --chat-lines:#00000033;
 --custom-select-hover:#FBFBFB;
 --custom-select-list-hover:#0000001a;
 --single-chat-hover:#1D1D1D;
 --single-chat-active-background:#ffffff;
 --model-input-border:none;
 --user-name-right-chat:#00000099;
 --closing-container-arrow:#DFDFDF;
 --closing-container-arrow-border:#DFDFDF;
 --closing-arrow-image-filter:unset;
 --token-count-container:#ffffff;
 --token-count-text:#1D1D1D;
 --input-message-border:#ffffff;
 --message-input-placeholder:#00000080;
 --dynamic-token-count-background:#f8f8f8;
 --tags-text:#1D1D1D;
 --dataset-filter-background:#f8f8f8;
 --select-option-ul-icon-filter:brightness(0) opacity(1) var(--black-image-filter);
 --track-zero:#00000066;
 --track-one:#00000033;
 --star-active-filter:unset;
 --collapse-arrow-hover:#D2D2D2;
 --tags-boxes-border:#00000080;
 --toggle-tracker-color:#0000001a;
 --indexer-box-background:#1D1D1D;
 --indexer-box-color:#ffffff;
 --hint-box-opacity:0.3;
 --custom-tag-arrow:invert(0) brightness(0);
 --dataset-filter-add-button:#ffffff;
 --profile-logout:transparent;
 --popup-background:unset;
 --hint-box-para-text:#00000099;
 --hint-box-link-text:#00000080;
 --footer-text-hover:#00000066;
 --footer-text-hover-other:#7f7f7f;
 --custom-select-background:#ffffff;
 --stop-box-stroke:#1D1D1D;
 --single-message-select:#0000001a;
 --tags-hover:#0000001a;
 --chat-boxes-border:#1D1D1D;
 --services-icon-filter: invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(109%) contrast(107%);
 --light-mobile-home:#B79BFF;
 --home-mobile-header-logo:invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(150%) contrast(107%);
 --api-chat-image:unset;
 --collaborators-logo-filter: brightness(0);
 --home-header-gradient:#B79BFF;
 --text-logo-filter:brightness(0) invert(1);
 --home-header-button-border:#ffffff;
 --home-header-button-background:#B79BFF;
 --home-header-button-hover:#ffffff;
 --home-header-button-hover-text:#B79BFF;
 --home-header-theme-filter:invert(60%) sepia(6%) saturate(3550%) hue-rotate(212deg) brightness(109%) contrast(107%);
  
 --background-colour: var(--white);
 --text-color: var(--dark-purple);
 --chat-image-filter: unset;
 --chat-user-image-filter: unset;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* For Python comments */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: rgba(128, 128, 128, 0.9); /* Adjust the opacity as needed */
}

/* For JavaScript comments */
.token.comment {
  color: rgba(128, 128, 128, 0.7); /* Adjust the opacity as needed */
}

/* For Curl comments */
.token.curl-comment {
  color: rgba(128, 128, 128, 0.7); /* Adjust the opacity as needed */
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.scrollable-div-table{
  overflow: auto;
}
.react-swipeable-view-container::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Webkit browsers */
  height: 0; /* Hide horizontal scrollbar for Webkit browsers */
  transition: 0.3s;
  display: none;
}
 .markdown-table table {
  border-collapse:unset;
  border-spacing: 0px 0; /* Adjust this value to your preference */
  font-family:  Rubik;
  border-radius: 5px;
  border: 0.5px solid var(--grey);
}
.markdown-table img{
  width:15px;
  height:auto;
  filter: invert(1);
}
.markdown-table button{
    color: var(--white);
    padding: 5px 7px;
    border-radius: 5px;
    font-size: var(--l-l-small);
    font-family: Rubik;
    background: var(--chat-buttons);
    border: 1px solid var(--chat-header-border);
    display: flex;
    flex-direction:row-reverse;
    align-items:center;
    gap:5px;
    cursor: pointer;
    align-self: end;
}
.TextContainer  p:nth-child(2){
  display:none !important;
}
.IndexerLoaderText{
  color: var(--home-page-black-text);
  font-family: Rubik;
}
.__floater__arrow svg polygon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  height: 10px; /* Adjust the height of the bottom border */
  background-color: var(--home-page-background); /* Set the bottom border color to black */
  z-index: 1; /* Ensure the border is above the arrow */
}
.__floater__arrow svg polygon {
  fill: var(--home-page-background);
  stroke: var(--dark-purple);
  z-index: -1;
}
.markdown-table td{
  padding: 5px 15px; /* Adjust this value to your preference */
  font-family:  Rubik;
  border: 0.5px solid var(--grey);
  text-align: left;
}
.markdown-table th {
  padding: 5px 15px;/* Adjust this value to your preference */
  font-family:  Rubik;
  border: 0.5px solid var(--grey);
  background-color: #F5F5F6;
  text-align: left;
  color: var(--black-color);
}
.markdown-table thead{
  border: 0.5px solid var(--grey);
  text-align: left;
}
.markdown-table p{
  font-family:  Rubik;
}
 .WholeBodyWrapper{
  overflow-x: hidden;
  position: relative;
  height: 100%;
}
#text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    padding: 20px;
    text-align: center;
    font-family: Rubik,Arial, sans-serif;
    font-size: 18px;
    color: #000000;
    background-color: #ffffff;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
#text-container:hover {
    color: #000000;
    background-color: #AF9EDD;
    border-color: #ffffff;
}

canvas[data-engine="three.js r155"]{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
}
.react-toggle--checked .react-toggle-track{
  background-color: var(--purple)!important;
  opacity: 0.6;
}

  
.react-toggle-thumb{
  /* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms!important; */
  border: 1px solid var(--purple);
  width: 28px!important;
  height: 28px!important;
  top: -4px!important;
  border:none!important;
  left:0px!important;
  background-color: var(--white)!important;
  border: 1px solid var(--black-color)!important;
  /* transition: all 5s ease!important; */
  /* opacity: 0.6; */
}
.react-toggle--focus .react-toggle-thumb{
  border: 1px solid var(--purple);
  width: 28px!important;
  height: 28px!important;
  top: -4px!important;
  border:none!important;
  background-color: var(--white)!important;
  border: 1px solid var(--black-color)!important;
  box-shadow: none!important;
  /* transition: all 5s ease!important; */
}
.react-toggle--checked .react-toggle-thumb{
  border: 1px solid var(--purple);
  width: 28px!important;
  height: 28px!important;
  top: -4px!important;
  border:none!important;
  background-color: var(--purple)!important;
  border: 1px solid var(--purple)!important;
  /* transition: all 5s ease!important; */
  /* opacity: 0.6; */
  /* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms!important; */
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none!important;
}
.react-toggle-track {
  width: 55px!important;
  height: 20px!important;
  background-color:var( --toggle-tracker-color)!important;
  border-radius: 60px!important;
  /* transition: all 5s ease!important; */
}
.react-toggle--checked  .react-toggle-thumb{
  left: 30px!important;
  border:none!important;
  /* transition: all 5s ease!important; */
}
button{
  color: var(--black-color);
}

.ql-container.ql-snow{
  border: none!important;
}
 pre[class*="language-"]{
  margin: -20px 0 -20px 0!important;
  padding: 0px !important;
  background:var(--code-background) !important;
  position: relative;
 }
 pre[class*="language-api-"]{
  margin: 0px!important;
 }
 #ModelRenderCodeId{
  margin: 0px!important;
  background: var(--code-background);
 }
 #HomePageCode{ 
  color: #F5F5F6 !important;
   margin: 0px!important;
  background: #1e1e1e !important;
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  font-size: 12px;
 }
div[class*="language-"]{
  margin: 0px 0 0px 0!important;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

code[class*="language-"]{
  color: var(--home-page-black-text) !important;
  text-shadow: none !important;
}

.token.operator{
  background: transparent !important;
}
.recharts-surface{
  width: 100% !important;
}
@media (max-width:768px)  {
  .recharts-wrapper{
    left: -25px !important;
  }
}
@media(max-width:1024px) {
  *:focus {
    outline: none;
}

a, button, input, textarea,div,li {
    -webkit-tap-highlight-color: transparent;
}
}
@media screen and (max-width:425px) {
  body ::-webkit-scrollbar{
    width: 0px;
    height: 0px;
    display: none;
  }
}
