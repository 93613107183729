.InnerContainer{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999999999999;
    backdrop-filter: blur(5px);
    top: 0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.MainContainer p{
     color: var(--home-page-black-text);;
}
.MainContainer img{
    /* border: 2px solid var(--border-color);
    opacity: 1;
    width: 40%;
    margin: 150px auto 0 auto;
    background-color: var(--background-color);
    transition: background-color 1s;
    padding: 25px; */
    backdrop-filter: blur(5px);
    width: 120px;
    height: auto;
    filter: var(--custom-tag-arrow);
}